import { __awaiter } from "tslib";
import { getDbCore, getConnectionInfo, getSqlObjectInfo } from './metadataLoaders';
import sqlFormatter from 'sql-formatter';
import { driverBase, findEngineDriver } from 'dbgate-tools';
function extractDbObjectInfo(dbinfo, { objectTypeField, pureName, schemaName }) {
    if (!dbinfo)
        return null;
    return dbinfo[objectTypeField || 'tables'].find(x => x.pureName == pureName && x.schemaName == schemaName);
}
function generateTableSql(extensions, props, dumpProc, format = false, dbinfo, connectionInfo) {
    return __awaiter(this, void 0, void 0, function* () {
        const tableInfo = dbinfo
            ? extractDbObjectInfo(dbinfo, props)
            : yield getDbCore(props, props.objectTypeField || 'tables');
        const connection = connectionInfo || (yield getConnectionInfo(props));
        const driver = findEngineDriver(connection, extensions) || driverBase;
        const dmp = driver.createDumper();
        if (tableInfo)
            dumpProc(dmp, tableInfo);
        return format ? sqlFormatter.format(dmp.s) : dmp.s;
    });
}
export default function applyScriptTemplate(scriptTemplate, extensions, props, dbinfo, connectionInfo) {
    return __awaiter(this, void 0, void 0, function* () {
        if (scriptTemplate == 'CREATE TABLE') {
            return generateTableSql(extensions, props, (dmp, tableInfo) => dmp.createTable(tableInfo), false, dbinfo, connectionInfo);
        }
        if (scriptTemplate == 'SELECT') {
            return generateTableSql(extensions, props, (dmp, tableInfo) => {
                dmp.put('^select %,i ^from %f', tableInfo.columns.map(x => x.columnName), tableInfo);
            }, true, dbinfo, connectionInfo);
        }
        if (scriptTemplate == 'CREATE OBJECT') {
            const objectInfo = dbinfo ? extractDbObjectInfo(dbinfo, props) : yield getSqlObjectInfo(props);
            if (objectInfo) {
                if (objectInfo.requiresFormat && objectInfo.createSql)
                    return sqlFormatter.format(objectInfo.createSql);
                else
                    return objectInfo.createSql;
            }
        }
        if (scriptTemplate == 'ALTER OBJECT') {
            const objectInfo = dbinfo ? extractDbObjectInfo(dbinfo, props) : yield getSqlObjectInfo(props);
            if (objectInfo) {
                const createSql = objectInfo.requiresFormat && objectInfo.createSql
                    ? sqlFormatter.format(objectInfo.createSql)
                    : objectInfo.createSql || '';
                const alterPrefix = createSql.trimStart().startsWith('CREATE ') ? 'ALTER ' : 'alter ';
                return createSql.replace(/^\s*create\s+/i, alterPrefix);
            }
        }
        if (scriptTemplate == 'CALL OBJECT') {
            const procedureInfo = dbinfo ? extractDbObjectInfo(dbinfo, props) : yield getSqlObjectInfo(props);
            const connection = connectionInfo || (yield getConnectionInfo(props));
            const driver = findEngineDriver(connection, extensions) || driverBase;
            const dmp = driver.createDumper();
            if (procedureInfo) {
                dmp.callableTemplate(procedureInfo);
            }
            return dmp.s;
        }
        const connection = connectionInfo || (yield getConnectionInfo(props));
        const driver = findEngineDriver(connection, extensions) || driverBase;
        const res = yield driver.getScriptTemplateContent(scriptTemplate, props);
        return res;
    });
}
export function getSupportedScriptTemplates(objectTypeField) {
    switch (objectTypeField) {
        case 'tables':
            return [
                { label: 'CREATE TABLE', scriptTemplate: 'CREATE TABLE' },
                { label: 'SELECT', scriptTemplate: 'SELECT' },
            ];
        case 'views':
            return [
                {
                    label: 'CREATE VIEW',
                    scriptTemplate: 'CREATE OBJECT',
                },
                {
                    label: 'ALTER VIEW',
                    scriptTemplate: 'ALTER OBJECT',
                },
                {
                    label: 'CREATE TABLE',
                    scriptTemplate: 'CREATE TABLE',
                },
                {
                    label: 'SELECT',
                    scriptTemplate: 'SELECT',
                },
            ];
        case 'matviews':
            return [
                {
                    label: 'CREATE MATERIALIZED VIEW',
                    scriptTemplate: 'CREATE OBJECT',
                },
                {
                    label: 'ALTER MATERIALIZED VIEW',
                    scriptTemplate: 'ALTER OBJECT',
                },
                {
                    label: 'CREATE TABLE',
                    scriptTemplate: 'CREATE TABLE',
                },
                {
                    label: 'SELECT',
                    scriptTemplate: 'SELECT',
                },
            ];
        case 'procedures':
            return [
                {
                    label: 'CREATE PROCEDURE',
                    scriptTemplate: 'CREATE OBJECT',
                },
                {
                    label: 'ALTER PROCEDURE',
                    scriptTemplate: 'ALTER OBJECT',
                },
                {
                    label: 'EXECUTE',
                    scriptTemplate: 'CALL OBJECT',
                },
            ];
        case 'functions':
            return [
                {
                    label: 'CREATE FUNCTION',
                    scriptTemplate: 'CREATE OBJECT',
                },
                {
                    label: ' ALTER FUNCTION',
                    scriptTemplate: 'ALTER OBJECT',
                },
                {
                    label: 'CALL',
                    scriptTemplate: 'CALL OBJECT',
                },
            ];
        case 'triggers':
            return [
                {
                    label: 'CREATE TRIGGER',
                    scriptTemplate: 'CREATE OBJECT',
                },
            ];
        case 'schedulerEvents':
            return [
                {
                    label: 'CREATE SCHEDULER EVENT',
                    scriptTemplate: 'CREATE OBJECT',
                },
            ];
    }
    return [];
}
